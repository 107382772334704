import styled from 'styled-components'

export const Wrap = styled.div`
  overflow-x: hidden;
  height: 100%;

  > div {
    height: 100%;
  }
`
